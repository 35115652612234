import React from "react";
import {
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { Paper, Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import ImagePlaceHolder from "../../Components/GibsonsComponents/ImagePlaceHolder";
import { NavLink } from "react-router-dom";
import Constants from "../../Components/Helpers/Constants";
import allStyles from "../../Styles/allStyles";
import { Link } from "react-router-dom";


const ProductsLoop = (props) => {
  const classes = allStyles();
  const products = props.products;
  const addAndGoToCart = props.addAndGoToCart;

  return (
    <div>
      {products.map((product) => (
        <Box
          p={2}
          my={2}
          key={product.id}
          component={Paper}
          className={classes.productWrapper}
          style={{ transition: "all ease-out .25s" }}
          variant="outlined"
        >
          <Grid container>
            <Grid item sm={4} display="flex" justifyContent="center" style={{
                width: "100%",
                alignContent: "center",
                display: "flex",
              }}>
              <Link
                to={`/shop/product/${product.id}`}
              >
              
                {product.images.length == 0 ? (
                  <Box px={2}>
                    <ImagePlaceHolder width="80%" hTop={20} hBottom={70} />
                  </Box>
                ) : (
                  <img
                    src={Constants.api + product.images[0].file_name}
                    height="150"
                  />
                )}
              </Link>
            </Grid>
            <Grid
              item
              sm={8}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box>
                <Typography
                  paragraph
                  variant="body1"
                  className={classes.productTitle}
                >
                  {product.title}
                </Typography>
                <Typography paragraph className={classes.price}>
                  {Constants.currency(product.currency, product.price)}
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Button
                      exact
                      component={NavLink}
                      to={`/shop/product/${product.id}`}
                      size="small"
                      variant="outlined"
                      color="secondary"
                    >
                      View Details
                    </Button>
                  </Grid>
                  <Grid item xs={6} align="right">
                    <Button
                      size="small"
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={() => addAndGoToCart(product)}
                    >
                      Buy Now
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </div>
  );
};

export default ProductsLoop;
